import React, { useState, useEffect } from 'react';
import { TextField, PrimaryButton, Stack, DetailsList, DetailsListLayoutMode, SelectionMode, Selection, Dialog, DialogType, DialogFooter, DefaultButton } from '@fluentui/react';
import "./App.css"
import { FaRegCopy, FaCheck } from "react-icons/fa";
import { IoReload } from "react-icons/io5";
import { PublicClientApplication } from '@azure/msal-browser';
import Loading from './Loading';

const msalConfig = {
  auth: {
    clientId: 'fc20ec05-fa4a-4de9-b459-e4b1b00e7762',
    authority: 'https://login.microsoftonline.com/cf3767e0-0cba-4bd6-9e43-02cf44292a95',
    redirectUri: 'https://keypass.ioz.ch',
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
const scopes = ["user.read"];
await msalInstance.initialize();

const Icon = ({ item }) => {
  const [copied, setCopied] = useState(false);
  const [isRefreshDialogVisible, setIsRefreshDialogVisible] = useState(false);


  const copyToClipboard = () => {
    navigator.clipboard.writeText(item.key)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 750);
      })
      .catch(err => console.error('Failed to copy key to clipboard:', err));
  };

  const RefreshKey = async () => {
    setIsRefreshDialogVisible(true);
  };

  const confirmRefreshKey = async () => {
    setIsRefreshDialogVisible(false);

    try {
      const requestBody = {
        id: item.id
      };

      const response = await fetch('https://key-generator-functions.azurewebsites.net/api/UpdateKey?code=oPUbSJRHad5cQ6YH5JYtZdMQ4iyFFYZwpaXVwEcd7OEyAzFuWEzZUA==', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      window.location.reload();

    } catch (error) {
      console.error("Error fetching keys:", error);
    }
  };



  return (
    <>
      {copied ? (
        <>
          <FaCheck size={15} className="icon-margin" />
          <IoReload size={15} onClick={RefreshKey} />
        </>
      ) : (
        <>
          <FaRegCopy size={15} onClick={copyToClipboard} className="icon-margin" />
          <IoReload size={15} onClick={RefreshKey} />
        </>
      )}
      <Dialog
        hidden={!isRefreshDialogVisible}
        onDismiss={() => setIsRefreshDialogVisible(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: 'Bestätigung',
          subText: 'Möchtest du den Key aktualisieren?',
        }}
        modalProps={{
          isBlocking: false,
        }}
      >
        <DialogFooter>
          <PrimaryButton onClick={confirmRefreshKey} text="Ja" />
          <DefaultButton onClick={() => setIsRefreshDialogVisible(false)} text="Nein" />
        </DialogFooter>
      </Dialog>
    </>
  );
}

function App() {

  const [key, setKey] = useState('');
  const [copySuccess, setCopySuccess] = useState('');
  const [items, setItems] = useState([]);
  const [groups, setGroups] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [msalToken, setMsalToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);



  const columns = [
    { key: 'column1', name: 'Produkt', fieldName: 'product', minWidth: 100, maxWidth: 200, isResizable: true },
    { key: 'column2', name: 'Key', fieldName: 'key', minWidth: 300, maxWidth: 300, isResizable: true },
    {
      key: 'column3',
      name: '',
      minWidth: 75,
      maxWidth: 75,
      isResizable: true,
      onRender: (item) => {
        return <Icon item={item} />;
      },
    }
  ];

  const handleLogin = async () => {
    if (!msalToken) {
      try {
        const tokenResponse = await msalInstance.handleRedirectPromise();
        if (tokenResponse) {
          msalInstance.setActiveAccount(tokenResponse.account);
          window.location.reload();
        } else {
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        }

        const account = msalInstance.getActiveAccount();
        if (account && tokenResponse) {
          console.log("[AuthService] Got valid accountObj and tokenResponse");
          setMsalToken(tokenResponse.accessToken);
        } else if (account) {
          console.log("[AuthService] User has logged in, but no tokens.");
          try {
            const silentTokenResponse = await msalInstance.acquireTokenSilent({ account, scopes });
            setMsalToken(silentTokenResponse.accessToken);
            console.log("[AuthService] Token acquired")
          } catch (error) {
            console.log("[AuthService] Token could not be acquired silently");
            await msalInstance.acquireTokenRedirect({ account, scopes });
          }
        } else {
          console.log("[AuthService] No accountObject or tokenResponse present. User must now login.");
          await msalInstance.loginRedirect({ scopes });
        }
      } catch (error) {
        console.error("[AuthService] Failed to handleRedirectPromise()", error);
      }
      finally {
        setIsLoading(false);
      }
    }
    else {
      setIsLoading(false);
    }
  };

  const fetchKeys = async () => {
    try {
      const response = await fetch('https://key-generator-functions.azurewebsites.net/api/GetKeys?code=Ha38A9OhIQNz1jAsFhwRBXYYJHBfEyCcFDlXLAAcM3QVAzFu9ArrbQ==');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setItems(data);
    } catch (error) {
      console.error("Error fetching keys:", error);
    }
  };

  const createGroups = (items) => {
    const groupMap = {};
    items.forEach(item => {
      groupMap[item.company] = groupMap[item.company] || [];
      groupMap[item.company].push(item);
    });

    let startIndex = 0;
    const groups = Object.keys(groupMap).map(company => {
      const group = {
        key: company,
        name: company,
        startIndex: startIndex,
        count: groupMap[company].length,
        level: 0,
      };
      startIndex += group.count;
      return group;
    });

    return groups;
  };

  useEffect(() => {
    handleLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchKeys();
  }, [key]);

  useEffect(() => {
    const sortedItems = items.sort(function (a, b) {
      if (a.company < b.company) return -1;
      if (a.company > b.company) return 1;
      return 0;
    })

    const newGroups = createGroups(sortedItems);
    setGroups(newGroups);
  }, [items, key]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(key).then(
      () => {
        setCopySuccess('Key kopiert!');
        setTimeout(() => setCopySuccess(''), 3000);
      },
      (err) => {
        console.error('Error in copying text: ', err);
      }
    );
  };

  const generateKey = async (event) => {
    event.preventDefault();

    const product = event.target.product.value.trim();
    const company = event.target.company.value.trim();

    const requestBody = {
      product: product,
      company: company,
    };

    try {
      const response = await fetch('https://key-generator-functions.azurewebsites.net/api/GenerateKey?code=sAkPWuXwpIeheqX9riW8G_-bhyh6LxxDNJRK7yxe58fZAzFuZHOwtA==', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const key = await response.json();
      setKey(key.key)
    } catch (error) {
      console.error("Error generating key:", error);
      setKey("Fehler")
    }
  };

  const selection = new Selection({
    onSelectionChanged: () => {
      setSelectedItems(selection.getSelection());
    }
  });

  const confirmDeletion = async () => {
    setIsDeleteDialogVisible(false);

    if (selectedItems.length === 0) {
      return;
    }

    const idToDelete = selectedItems[0];

    try {
      const response = await fetch('https://key-generator-functions.azurewebsites.net/api/DeleteKey?code=tfu_FR9jGCHgi2Qs3sujXewtICJqu_SRdqaVO72yvGR9AzFuzfzCRA==', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(idToDelete)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const remainingItems = await response.json();
      setItems(remainingItems);
      setSelectedItems([]);

    } catch (error) {
      console.error("Error deleting keys:", error);
    }
  };


  const deleteSelection = () => {
    if (selectedItems.length === 0) {
      return;
    }
    setIsDeleteDialogVisible(true);
  };

  return (
    isLoading || !msalToken ?
      <Loading /> :
      (
        <Stack horizontalAlign="center" verticalFill>
          <Stack horizontal tokens={{ childrenGap: 50 }} verticalAlign="center" className="innerContainer">
            <Stack.Item align="start" className="formContainer">
              <form onSubmit={generateKey}>
                <Stack tokens={{ childrenGap: 15 }}>
                  <TextField label="Unternehmen" id="company" />
                  <TextField label="Produkt" id="product" />
                  <PrimaryButton type="submit" text="Key erstellen" />
                  {key && (
                    <div onClick={copyToClipboard} className="keyDisplay">
                      {key}
                    </div>
                  )}
                  {copySuccess && <div className="copySuccess">{copySuccess}</div>}
                </Stack>
              </form>
            </Stack.Item>
            <Stack.Item align="start" className="detailsListContainer">
              <PrimaryButton text="Auswahl löschen" onClick={deleteSelection} disabled={selectedItems.length === 0} />
              <DetailsList
                items={items}
                columns={columns}
                groups={groups}
                layoutMode={DetailsListLayoutMode.justified}
                enterModalSelectionOnTouch={true}
                selectionMode={SelectionMode.single}
                selection={selection}
              />
            </Stack.Item>
          </Stack>
          <Dialog
            hidden={!isDeleteDialogVisible}
            onDismiss={() => setIsDeleteDialogVisible(false)}
            dialogContentProps={{
              type: DialogType.normal,
              title: 'Bestätigung',
              subText: 'Möchtest du den ausgewählten Key löschen?',
            }}
            modalProps={{
              isBlocking: false,
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={confirmDeletion} text="Ja" />
              <DefaultButton onClick={() => setIsDeleteDialogVisible(false)} text="Nein" />
            </DialogFooter>
          </Dialog>

        </Stack>
      )
  );
}

export default App;
