import React from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react';

const Loading = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <Spinner size={SpinnerSize.large} label="Loading..." ariaLive="assertive" labelPosition="right" />
    </div>
  );
};

export default Loading;
